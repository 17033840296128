import * as React from "react";
import { Dialog, DialogContent, DialogTitle, Theme, useMediaQuery } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { ReactComponent as MailIcon } from "./images/mail.svg";
import { FeeedbackForm } from "./FeeedbackForm";
import { YM } from "../common";

import styles from "./MailForm.module.scss";

type IProps = {};

const MailForm: React.FC<IProps> = () => {
  const [isOpenModal, setOpenModal] = React.useState(false);
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleOpenModal = () => {
    YM.sendReachGoal('mailIconButton');
    setOpenModal(true);
  };

  return (
    <>
      <MailIcon className={styles.Mail} onClick={handleOpenModal} />
      <Dialog
        onClose={handleCloseModal}
        open={isOpenModal}
        maxWidth={"sm"}
        fullWidth
        sx={{ "& .MuiPaper-root": { backgroundColor: "#505050" } }}
      >
        <DialogTitle
          sx={{
            fontSize: isMobile ? "24px" : "30px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
           Напишите нам
          <div
            onClick={handleCloseModal}
            className={styles.CloseIcon}
          >
            <CloseIcon />
          </div>
        </DialogTitle>
        <DialogContent>
          <FeeedbackForm />
        </DialogContent>
      </Dialog>
    </>
  );
};

export { MailForm };
