const countStartEnd = 99439076;

export class YM {
  static init = () => {
    if (process.env.NODE_ENV === "production") {
      ym(countStartEnd, "init", {
        clickmap: true,
        trackLinks: true,
        accurateTrackBounce: true,
      });
    }
  };

  static sendParams = (params: object) => {
    if (process.env.NODE_ENV === "production") {
      ym(countStartEnd, "params", {
        ...params,
      });
    }
  };

  static sendReachGoal = (target: string) => {
    if (process.env.NODE_ENV === "production") {
      ym(countStartEnd, "reachGoal", target);
    }
  };
}
