import * as React from "react";
import moment from "moment/moment";
import { Snackbars, YM } from "./common";
import { LandingScreen } from "./LandingScreen";

import "moment/locale/ru";
moment.locale("ru");

function App() {

  React.useLayoutEffect(() => {
    YM.init();
  }, []);


  React.useEffect(() => {
    YM.sendReachGoal('openLanding')
  },[])

  return (
    <>
      <LandingScreen />
      <Snackbars />
    </>
  );
}

export { App };
