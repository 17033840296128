import * as React from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import ReCAPTCHA from "react-google-recaptcha";
import * as yup from "yup";
import { Button, TextField } from "@mui/material";
import { useAddingFeeedback, useResolver } from "../hooks";
import { TextAreaField, YM } from "../common";

import styles from "./FeeedbackForm.module.scss";

const sitekey = '6LfRRwEqAAAAAOqzT8GbIe2vpK0I8gYnkutYEfDM';

const validationSchema: yup.Schema<IForm> = yup.object({
  name: yup.string().required("обязательне поле"),
  email: yup
    .string()
    .defined()
    .test((value, ctx) => {
      if (!value) {
        return ctx.createError({ message: "обязательне поле" });
      }
      if (!/\S+@\S+\.\S+/.test(value)) {
        return ctx.createError({
          message: "должен быть формат, пример: example@mail.ru",
        });
      }
      return true;
    }),
  comment: yup.string().required("обязательне поле"),
});

type IForm = {
  name: string;
  email: string;
  comment: string;
};

type IProps = {};

const FeeedbackForm: React.FC<IProps> = () => {
  const captchaRef = React.useRef<any>(null);
  const [token, setToken] = React.useState<string | null>("");
  const { addFeeedback, loading } = useAddingFeeedback();
  const resolver = useResolver<IForm>(validationSchema);
  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<IForm>({
    resolver,
    defaultValues: {
      name: "",
      email: "",
      comment: "",
    },
  });

  const onFormSubmit: SubmitHandler<IForm> = React.useCallback(
    async (data) => {
      if (token) {
        YM.sendReachGoal('sendEmailButton');
        captchaRef.current?.reset();
        addFeeedback({ ...data, token });
      }
    },
    [token, addFeeedback]
  );

  return (
    <form className={styles.Form} onSubmit={handleSubmit(onFormSubmit)}>
      <Controller
        control={control}
        name="name"
        shouldUnregister={true}
        render={({ field }) => (
          <TextField
            label="Ваше имя"
            variant="outlined"
            autoComplete="off"
            sx={{ marginBottom: "24px", minHeight: "56px" }}
            error={!!errors.name}
            helperText={!!errors.name ? errors.name.message : ""}
            {...field}
          />
        )}
      />
      <Controller
        control={control}
        name="email"
        shouldUnregister={true}
        render={({ field }) => (
          <TextField
            label="E-mail"
            variant="outlined"
            type="text"
            inputProps={{
              autoComplete: "off",
              form: {
                autoComplete: "off",
              },
            }}
            sx={{ marginBottom: "20px", minHeight: "56px" }}
            error={!!errors.email}
            helperText={!!errors.email ? errors.email.message : ""}
            {...field}
          />
        )}
      />
      <TextAreaField
        name="comment"
        placeholder="Тект сообщения"
        error={errors.comment?.message}
        control={control}
      />
      <ReCAPTCHA
        sitekey={sitekey}
        ref={captchaRef}
        onChange={(token) => {
          setToken(token);
        }}
        size="compact"
      />
      <Button
        variant="contained"
        type="submit"
        sx={{
          height: "56px",
          fontSize: "16px",
          fontWeight: 600,
        }}
        disabled={loading || !token}
      >
        Отправить
      </Button>
    </form>
  );
};

export { FeeedbackForm };
