import * as React from "react";
import { useTheme } from "@mui/material/styles";
import { ReactComponent as StarIcon } from "./images/star.svg";
import { YM } from "../common";

import styles from "./BannerContent.module.scss";

type IProps = {};

const BannerContent: React.FC<IProps> = () => {
  const theme = useTheme();

  return (
    <div className={styles.Content}>
      <div className={styles.Stars}>
        {Array.from({ length: 5 }, (k, i) => (
          <StarIcon key={i} className={styles.Star} />
        ))}
      </div>
      <h1 className={styles.Text}>
        Лучший сервис для поиска клубов по игре в мафию
      </h1>
      <a
        style={{
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.text.primary,
          textTransform: "uppercase",
          fontWeight: 600,
        }}
        className={styles.Button}
        href="https://mafia.stargamers.io"
        onClick={() => {
          YM.sendReachGoal("findGameButton");
        }}
      >
        Найти игру
      </a>
    </div>
  );
};

export { BannerContent };
